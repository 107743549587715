<template>
  <div class="flex auth-page">
    <div v-if="loading" class="has-centered-item">
      <base-spinner size="48" />
    </div>
    <div v-else-if="success" class="text-center">
      <base-empty :image="require('@/assets/image/EmailSuccess.svg')" />
      <p class="ce-title">{{ $t('account.confirmEmail.success.title') }}</p>
      <p class="mt-4">{{ $t('account.confirmEmail.success.description') }}</p>
      <p>
        (<a :href="['mailto:' + email]" class="text-blue-400">{{ email }}</a
        >)
      </p>
      <div class="w-full mt-8">
        <base-button class="w-64" @click="goToLogin">
          {{ $t('login.gotoBoard') }}
        </base-button>
      </div>
    </div>
    <div v-else class="text-center">
      <base-empty :image="require('@/assets/image/EmailFail.svg')" />
      <p class="ce-title">{{ $t('account.confirmEmail.fail.title') }}</p>
      <p class="mt-4">{{ $t('account.confirmEmail.fail.description') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      success: false,
      loading: true
    };
  },
  computed: {
    email() {
      if (this.$route.params.email) {
        return this.$route.params.email;
      } else {
        return this.$route.query.email;
      }
    }
  },
  mounted() {
    if (this.$route.params.email) {
      this.validateEmail();
    } else {
      this.verfiyEmail();
    }
  },
  methods: {
    async validateEmail() {
      try {
        const payload = {
          code: this.$route.params.code,
          email: this.$route.params.email
        };
        await this.$store.dispatch('auth/validateEmailChange', payload);
        await this.$store.dispatch(
          'auth/verifyEmail',
          this.$route.params.email
        );
        this.success = true;
      } catch (err) {
        this.success = false;
      }
      this.loading = false;
    },
    async verfiyEmail() {
      try {
        await this.$store.dispatch('auth/verifyEmail', this.$route.query.email);
        this.success = true;
      } catch (err) {
        this.success = false;
      }
      this.loading = false;
    },
    goToLogin() {
      this.$router.push({ name: 'Boards-List' });
    }
  }
};
</script>

<style scoped>
.ce-title {
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
}
</style>
